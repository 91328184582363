import React, { useEffect } from 'react';
import 'components/header/_header.scss';
import { Logo } from 'assets/images';
import { routes } from 'common';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authExtraActions } from 'reduxStore/actions';
import { useGoogleLogin } from '@react-oauth/google';
import { authActions } from 'reduxStore/reducers/authSlice';
import { generalActions } from 'reduxStore/reducers/generalSlice';
import { IconGoogle } from 'assets/icons';
import { Dropdown } from 'components';
import { GOOGLE_CALENDAR_SCOPE, ALL_GOOGLE_SCOPES } from 'common/constants';
import { ENVIRONMENT, ZOOM_AUTHORIZATION_URL } from '../../config';
import { toast } from 'react-toastify';

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, isUserLoggedIn } = useSelector((state) => state.auth);

  const login = useGoogleLogin({
    onSuccess: (response) => dispatch(authExtraActions.googleLogin(response)),
    scope: ALL_GOOGLE_SCOPES.join(' '),
    flow: 'auth-code',
  });

  const loginWithoutGMScopes = useGoogleLogin({
    onSuccess: (response) => dispatch(authExtraActions.googleLogin(response)),
    scope: GOOGLE_CALENDAR_SCOPE,
    flow: 'auth-code',
  });

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const disconnectGoogleMeet = async () => {
    try {
      dispatch(authExtraActions.disconnectGoogleMeet()).then((response) => {
        if (response?.type === 'auth/disconnectGoogleMeet/fulfilled') {
          dispatch(authActions.logout());
          loginWithoutGMScopes();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const disconnectZoom = async () => {
    try {
      dispatch(authExtraActions.disconnectZoom()).then((response) => {
        if (response?.type === 'auth/disconnectZoom/fulfilled') {
          toast.success('Zoom Disconnected Successfully');
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(generalActions.resetData());
  }, [location]);

  return (
    <header>
      <div className='content-wrapper'>
        <div className='content'>
          <div className='logo'>
            <Link to={routes.LANDING}>
              <img src={Logo} alt='logo' loading='lazy' />
            </Link>
          </div>
          {isUserLoggedIn && (
            <div className='menu'>
              <Link
                to={routes.UPLOAD}
                className={location.pathname === routes.UPLOAD ? 'active' : ''}
              >
                Upload
              </Link>
              <Link
                to={routes.TRANSCRIPTIONS}
                className={location.pathname.includes(routes.TRANSCRIPTIONS) ? 'active' : ''}
              >
                Transcriptions
              </Link>
              <Link
                to={routes.SUMMARIES}
                className={location.pathname.includes(routes.SUMMARIES) ? 'active' : ''}
              >
                Summaries
              </Link>
            </div>
          )}
          {!isUserLoggedIn ? (
            <div className='login'>
              <a onClick={() => login()}>
                <img src={IconGoogle} alt='google' height={24} loading='lazy' />
                Sign in with Google
              </a>
            </div>
          ) : (
            <div className='logout'>
              {user && (
                <Dropdown
                  title={`${user?.first_name} ${user?.last_name}`}
                  options={[
                    user.is_google_meet_connected ? (
                      <a onClick={disconnectGoogleMeet}>Disconnect Google Meet</a>
                    ) : (
                      <a onClick={() => login()}>Connect Google Meet</a>
                    ),
                    ENVIRONMENT === 'prod' || ENVIRONMENT === 'stage' ? (
                      user.is_zoom_connected ? (
                        <a onClick={disconnectZoom}>Disconnect Zoom</a>
                      ) : (
                        <a onClick={() => (window.location.href = ZOOM_AUTHORIZATION_URL)}>
                          Connect Zoom
                        </a>
                      )
                    ) : null,
                    <a onClick={handleLogout}>Logout</a>,
                  ]}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
