import React from 'react';
import { IconHero } from 'assets/icons';
import 'pages/landing/_landing.scss';
import { Link } from 'react-router-dom';
import { routes } from 'common';
import { useSelector } from 'react-redux';

function Landing() {
  const { isUserLoggedIn } = useSelector((state) => state.auth);

  return (
    <div className='landing'>
      <div className='custom-container flex'>
        <div className='texts'>
          <h1>
            Speech to Text <span>Transcribe your speech in two steps</span>
          </h1>
          <p>
            Use Bilingual audio and video and transcribe them into one language. With the
            transcription, be able to generate summary and analysis
          </p>
          {isUserLoggedIn && (
            <div className='upload-section'>
              <Link className='upload-btn' to={routes.UPLOAD}>
                Upload File
              </Link>
            </div>
          )}
        </div>
        <div className='hero-img'>
          <img src={IconHero} alt='hero' loading='lazy' />
        </div>
      </div>
    </div>
  );
}

export default Landing;
