export const routes = {
  LANDING: '',
  LOGIN: '/login',
  CONNECT_ZOOM: '/connect-zoom',
  UPLOAD: '/upload',
  TRANSCRIPTIONS: '/transcriptions',
  TRANSCRIPTION_DETAIL: '/transcriptions/:id',
  SUMMARIES: '/summaries',
  SUMMARY_DETAIL: '/summaries/:id',
};
